<template>
    <div >
       <div v-if="childData=='lexiconAdd'">
          <lexiconAdd @child-event="handleChildEvent"/>
       </div>
       <div v-if="childData=='lexiconUpdate'">
          <lexiconUpdate :item="test" @child-event="handleChildEvent"/>
       </div>
    </div>
 
</template>

<script>
    import lexiconAdd from './lexicon-add.vue';
    import lexiconUpdate from './lexicon-update.vue';
    export default {
        components: { lexiconAdd,lexiconUpdate},
        data(){
            return{
                test:'',
                childData:'lexiconAdd'
            }
        },
        methods:{
            handleChildEvent(dataFromChild,cikuTest){
                this.childData = dataFromChild;
                this.test = cikuTest
                console.log(this.childData,this.test);
            }
        }
    }
</script>

<style>

</style>